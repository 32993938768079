<template>
  <div>
    <article>
      <h3 class="center">平台提现和活动规则说明</h3>
      <h5>一、活动参与资格</h5>
      <section>
        <p>1. 只有符合以下条件的用户才有资格参与本活动：</p>
        <p>拥有快音账号的用户；</p>
        <p>遵守快音平台规则和法律法规的用户；</p>
        <p>未被封禁或被禁止参与本活动的用户。</p>
        <p>2. 如果用户的账号被封禁或被禁止参与本活动，快音有权取消其活动参与资格。</p>
      </section>
      <h5>二、活动规则</h5>
      <section>
        <p>
          1.
          用户必须遵守快音平台规则和法律法规，不得进行任何作弊、欺诈或其他违反法律法规的行为，否则快音有权取消其活动参与资格并收回已发放的活动奖励。
        </p>
        <p>
          2.
          用户不得使用任何作弊工具、外挂、插件、群控系统或其他修改游戏数据的工具，否则快音有权取消其活动参与资格并收回已发放的活动奖励。
        </p>
        <p>
          3. 用户必须保证不得将活动奖励用于任何盈利或非法获利，否则快音有权取消其活动参与资格并收回已发放的活动奖励。
        </p>
        <p>
          4.
          用户如获得奖励后未按照指定方式领取奖励，未按照平台规则提现/兑换，或超出奖励提现/兑换的有效时间，均视为用户自愿放弃该奖励和提现/兑换的权利，奖励将被清零，平台将不会也无义务给予任何形式的补偿。
        </p>
        <p>
          5.
          用户应当每日登陆APP或者进入活动任务页面至少一次，否则可能被平台认定为不活跃用户并暂时停止发放任务奖励。连续30日不活跃或未登录本APP的用户将视为放弃奖励，现金收益和金币收益将被自动清零。
        </p>
      </section>
      <h5>三、活动奖励发放</h5>
      <section>
        <p>1. 活动奖励将在活动结束后一定时间内发放到符合条件的用户的账号中。</p>
        <p>2. 如果用户在活动期间违反活动规则或法律法规，快音有权取消其活动参与资格并收回已发放的活动奖励。</p>
        <p>3. 如果用户提供的个人信息不真实、不完整或无效，快音有权取消其活动参与资格并收回已发放的活动奖励。</p>
        <p>4. 如果用户在领取活动奖励后违反活动规则或法律法规，快音有权取消其活动参与资格并收回已发放的活动奖励。</p>
      </section>
      <h5>四、提现规则：</h5>
      <section>
        <p>
          1.
          用户收益达到最低提现金额且满足要求后可申请提现，日常提现额度每日限一次，新人提现额度限三次。平台根据活动情况提供临时提现额度或满足一定要求的可提现额度，具体额度、使用次数和要求以页面提示为准。
        </p>
        <p>
          2. 用户需确认支付宝或微信帐号是否正确及是否可用，支付宝帐号或微信若未实名认证或填写信息错误可能导致提现失败。
        </p>
        <p>3. 活动奖励可能延迟到账，提现通常会在72小时内到账，如遇双休日、节假日，到账时间可能会延长。</p>
        <p>
          4.
          用户应确保提供的信息准确无误，如因操作错误、不符合第三方支付平台要求、填写信息错误等导致不能提现，视为自愿放弃奖励，平台无需承担责任。
        </p>
        <p>
          5.
          在提现/兑换过程中，平台有权审核订单或限制提现/兑换帐号数量，并随时提高安全验证手段，如用户未能通过安全验证将无法提现/兑换，用户应自行承担不能提现/兑换导致的不利后果，平台不承担任何责任。
        </p>
        <p>
          6.
          用户不得采取违规手段参与活动，一经发现，平台有权限制或取消参与资格，追讨已领取的奖励，并保留追究法律责任的权利。
        </p>
        <p>
          7.
          用户通过平台举办的活动获得收益或奖励的，平台依法可能需要为用户代扣代缴税款或办理纳税申报。为履行上述法定义务，平台需依照税务机关实际要求，收集并提供用户实名信息、收益金额等涉税信息和资料。如用户未向平台提供信息或提供错误信息，可能导致平台无法办理纳税申报或纳税申报错误。如平台无法办理，用户应自行申报纳税，由此造成的其他不利后果由用户自行承担。
        </p>
        <p>8. 平台有权对规则进行变动或调整，相关变动或调整将公布在规则页面上，并于公布时即时生效。</p>
      </section>

      <h5>五、活动规则变动和调整</h5>
      <section>
        <p>1. 快音有权在活动期间对活动规则进行变动和调整，相关变动和调整将在活动规则页面上公布，并于公布时即时生效。</p>
        <p>2. 用户在参加活动之前必须同意最新的活动规则，如果不同意，应停止参加该活动。</p>
      </section>
      <h5>六、免责条款</h5>
      <section>
        <p>
          1.
          在活动期间，因用户操作不当、网络故障、电信运营商故障、不可抗力等因素导致活动暂停或停止，快音不承担任何责任。
        </p>
        <p>2. 用户在参加活动过程中必须遵守法律法规和快音平台规则，否则快音有权取消其活动参与资格并追究相关法律责任。</p>
      </section>
      <h5>七、其他条款</h5>
      <section>
        <p>
          1.
          本活动规则与快音平台的《用户服务协议》、《个人信息保护政策》以及日常活动规则（统称为「前述协议」）、《快音社区管理规范》、《隐私权保护政策》等协议规则不一致的，以本活动规则为准。
        </p>
        <p>2. 本活动规则未约定的内容，以快音平台协议规则为准，如用户对本次活动或活动规则有任何问题可私信客服咨询。</p>
      </section>

      <p>以上是快音平台活动的规则文档，请用户在参加活动之前仔细阅读并遵守相关规则。</p>
    </article>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      document.title = '平台提现和活动规则说明';
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    /* text-indent: 2em; */
  }
  .center {
    text-align: center;
  }
  .sign {
    margin: 50px 0;
    text-align: right;
  }
</style>
